<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">
          <div class="page__content">
            <div class="sidebar__header">
              <h1 class="sidebar__title">Meus materiais</h1>
              <div class="buttons">
                <button type="button" class="button_outline" @click="modal = true">Adicionar Material</button>
              </div>
            </div>
            <div class="tabs__flex">
              <div class="tabs__nav">
                <div v-if="showMessage" class="alert">
                  <p>
                    <i class="fas fa-exclamation-circle"></i>
                    Alguns materiais estão 'Em análise', aguarde a aprovação para iniciar as coletas
                  </p>
                </div>
              </div>
              <div class="tabs__search">
                <div class="search__form">
                  <input class="form__input" type="search" placeholder="Pesquisar..." v-model="search" />
                  <button class="button search__button" type="submit">
                    <i class="icon icon-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="tab__body current" id="all">
              <table class="table">
                <thead>
                  <tr>
                    <th>Material</th>
                    <th style="width: 250px;">Cidade</th>
                    <th>Estado</th>
                    <th>Certificado</th>
                    <th>Validade</th>
                    <th>Coleta</th>
                    <th><span>Opções</span></th>
                  </tr>
                </thead>

                <tbody v-if="materials !== null">
                  <tr v-for="(material, index) in pagedMaterials" :key="index">
                      <td>
                        <span><span v-bind:style="{ backgroundColor: material[1][0].category_color  }" style="text-align: center; color: #FFFFFF;" class="dot">{{ Array.from(material[0])[0].toUpperCase() }}</span>{{ material[0] }}</span>
                      </td>
                      <td class="citiesCol">
                        <span v-for="(city, index) in material[1]" :key="index">{{ city.city }}</span>
                      </td>
                      <td class="citiesCol" style="text-align: center;">
                        <span v-for="(city, index) in material[1]" :key="index">{{ city.province }}</span>
                      </td>
                      <td class="citiesCol">
                        <span class="alert_card" v-for="(certified, index) in material[1]" :key="index">
                          {{certified.have_destination ? 'Sim' : 'Não'}}
                        </span>
                      </td>
                      <td class="citiesCol">
                        <span class="alert_card" v-for="(vality, index) in material[1]" :key="index">
                          <template v-if="vality.destination_certification_status">{{vality.destination_certification_expiration | formatDate}}</template>
                        </span>
                      </td>
                      <td class="citiesCol">
                        <span class="alert_card" :class="{
                          'alert': licenceStatus.licence_status === 'Aguardando',
                          'reprovado': licenceStatus.licence_status === 'Reprovado',
                          'aprovado': licenceStatus.licence_status === 'Aprovado'}" v-for="(licenceStatus, index) in material[1]" :key="index">
                          {{licenceStatus.licence_status == 'Aguardando' ? 'Em análise' : licenceStatus.licence_status}}
                        </span>
                      </td>
                      <td class="citiesCol">
                        <span v-for="(actions, index) in material[1]" :key="actions.id">
                          <button v-if="actions.licence_status === 'Aprovado'" @click="$router.push({name: 'collectionAgent', params: {id: actions.id, item: actions.item_id}})">
                            <i class="far fa-user agent"></i>
                          </button>
                          <button @click.prevent.stop="deleteMaterial(actions.item_id, actions.city_id, material[0], index)">
                            <i class="fas fa-trash delete"></i>
                          </button>
                          <button v-if="actions.licence_status === 'Reprovado' && actions.disapprove_observation !== null" @click.prevent.stop="openInfoModal(actions.disapprove_observation)">
                            <i class="fas fa-info-circle info"></i>
                          </button>
                        </span>
                      </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="paginator">
          <button type="button" class="paginator-control" :disabled="page == 1" :class="{ disabled: page == 1 }" @click="page--">
            <i class="fa fa-chevron-left"></i>
          </button>
          <div v-for="(pageNumber, index) in pages" :key="index" @click="page = pageNumber" class="paginator-control" :class="{active: pageNumber === page}"> {{pageNumber}} </div>
          <button type="button" @click="page++" :disabled="page == pages.length" :class="{ disabled: page == pages.length }" class="paginator-control">
            <i class="fa fa-chevron-right"></i>
          </button>
        </div>
      </div>
    </section>
    <modal-register v-model="modal" @change-modal="e => (modal = e)" :editing.sync="editing"></modal-register>
  </main>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import swal from 'sweetalert'
export default {
  data: () => ({
    error: null,
    search: '',
    load: true,
    modal: false,
    editing: {},
    page: 1,
    perPage: 5,
    pages: [],
    loading: false,
    materials: null,
    cardInfo: {
      materials: 0,
      cities: 0,
      agents: 0,
      collection_point: 0,
      not_sent: 0,
      disapproved: 0
    },
    modalAgent: false,
    agentEditing: {},
    showMessage: false
  }),
  methods: {
    async request () {
      const response = await axios.get('/collectors/materials')

      response.data.data.forEach((item) => {
        if (item.licence_status === 'Aguardando') {
          this.showMessage = true
        }
      })

      this.materials = _.groupBy(response.data.data, 'material')
      Object.entries(this.materials).forEach((item, index) => {
        this.materials[item[0]] = _.orderBy(item[1], 'city', 'asc')
      })
    },
    async deleteMaterial (item_id, city_id, material_name, material_index) {
      const confirmDelete = await swal({
        title: 'Inativar material',
        text: 'Deseja realmente inativar esse material? Somente o administrador poderá reverter essa operação.',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Cancelar',
            value: null,
            visible: true,
            className: 'swal-button--cancel',
            closeModal: true
          },
          confirm: {
            text: 'Confirmar',
            value: true,
            visible: true,
            className: 'swal-button--confirm',
            closeModal: true
          }
        },
        dangerMode: true
      })

      if (confirmDelete) {
        try {
          const url = '/collectors/materials'
          const response = await this.$http.delete(url, {
            headers: {
              'Content-Type': 'application/json'
            },
            data: {
              city_id: city_id,
              item_id: item_id
            }
          })
          if (response.status === 200) {
            await swal({
              title: 'Material inativado com sucesso!',
              icon: 'success'
            })
            await this.request()
            this.materials[material_name][material_index].status = 'Inativo'
          }
        } catch (err) {
          await swal('Oops!', 'Erro ao deletar material', 'error')
        }
      }
    },

    openEditModal (item) {
      this.editing = {}
      if (!item) return
      this.editing = item
      this.editing.have_destination === true ? this.editing.have_destination = 1 : this.editing.have_destination = 0
      this.modal = true
    },

    setPages (value) {
      this.pages = []
      const numberOfPages = Math.ceil(value.length / this.perPage)
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },

    paginate (value) {
      const page = this.page
      const perPage = this.perPage
      const from = (page * perPage) - perPage
      const to = (page * perPage)
      this.setPages(value)
      return value.slice(from, to)
    },

    async cards_info () {
      const response = await axios.get('/collectors/indicators/')
      this.cardInfo.materials = response.data.data.materials
      this.cardInfo.cities = response.data.data.cities
      this.cardInfo.disapproved = response.data.data.reproved
      this.cardInfo.not_sent = response.data.data.not_sent
      this.cardInfo.agents = response.data.data.collector_agents
      this.cardInfo.collection_point = response.data.data.collector_points
    },

    openInfoModal (value) {
      swal({
        title: 'Motivo reprova',
        text: value,
        icon: 'warning',
        button: false
      })
    }
  },
  computed: {
    pagedMaterials: function () {
      let filtered = ''
      if (this.search !== '' && this.search) {
        filtered = _.groupBy(_.flatMap(this.materials).filter((value) => {
          return value.material.includes(this.search) || value.city.includes(this.search)
        }), 'material')
        return this.paginate(Object.entries(filtered))
      } else {
        return this.paginate(Object.entries(this.materials))
      }
    }
  },
  async created () {
    // await this.cards_info()
    await this.request()
  },
  components: {
    modalRegister: () => import('./components/modal-register-material')
  },
  filters: {
    cover (val) {
      if (!val || !val.length || typeof val !== 'string') return 'M'
      return val.charAt(0)
    },
    decimal (val) {
      return `${parseFloat(val).toFixed(2)}`.replace('.', ',')
    },
    price (val) {
      return `R$ ${parseFloat(val).toFixed(2)}`.replace('.', ',')
    },
    formatDate (value) {
      if (value) {
        const date = new Date(value)
        return '' + (date.getDate() > 9 ? '' : '0') + date.getDate() + '/' + ((date.getMonth() + 1) > 9 ? '' : '0') + (date.getMonth() + 1) + '/' + date.getFullYear()
      }
    }
  },
  watch: {
    async modal (val) {
      if (!val) {
        await this.request()
      }
    }
  }
}
</script>

<style scoped>
.circle_initial {
  background: #16b666;
}
.sidebar__header {
  display: flex;
  justify-content: space-between;
}
.tabs__search {
  width: 40%;
}

.table {
  table-layout: fixed;
}

.button-suggection {
  color: #ffc43e;
  border-color: #ffc43e;
  margin-right: 0.5rem;
}

.table tbody tr{
  background: transparent;
  transition: all linear 0.2s;
}
.table tbody tr:hover{
  background: #eeeeee33;
}

th,
td {
  font-weight: 400;
  width: 100%;
  text-align: left;
}
th:nth-child(3),
th:nth-child(4),
th:nth-child(5),
th:nth-child(6),
th:nth-child(7){
  text-align: center !important;
}

td:first-child{
  vertical-align: top;
}
td:last-child{
  text-align: center !important;
}
td:last-child button{
  text-align: center;
  background: transparent;
  font-size: 15pt;
  margin: 0px 7px;
  transition: all linear 0.2s;
}
td:last-child button .edit{
  color: #009cceB3;
}
td:last-child button:hover .edit{
  color: #009cceFF;
}
td:last-child button .delete{
  color: #ff371aB3;
}
td:last-child button:hover .delete{
  color: #ff371aFF;
}
td:last-child button .agent{
  color: #808080B3;
}
td:last-child button:hover .agent{
  color: #808080FF;
}
td:last-child button .info{
  color: #FFA500B3;
}
td:last-child button:hover .info{
  color: #FFA500FF;
}
.citiesCol span{
  width: 100%;
  min-height: 23.5px;
  margin-bottom: 10px;
  display: inline-block;
}
.citiesCol span:last-child{
  margin-bottom: 0px;
}

td span {
  font-weight: 400;
  font-size: 16px;
}

tr {
  border-bottom: 1px solid #e5e5e5;
}

.alert {
  background-color: #fff3e2;
  color: #d87420;
  padding: 12px;
  border-radius: 7px;
}

.alert_card {
  min-height: 16px;
  font-size: 10pt;
  text-align: center;
  padding: 3px;
  border-radius: 4px;
}
.alert_card.inativo {
  background-color: #fff3e2;
  color: #914202;
}
.alert_card.aguardando {
  background-color: #fade8433;
  color: rgb(177, 121, 0);
}
.alert_card.aprovado {
  background-color: #E0F8E6;
  color: #16b666;
}
.alert_card.reprovado,
.alert_card.naoEnviado{
  background-color: #FFE6E0;
  color: #FF371A;
}
.alert_card.semCertificado {
  background-color: #eee;
  color: #333;
}

.circle_initial {
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 7px;
  position: relative;
}

.circle_initial p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.flex-td {
  display: flex;
  align-items: center;
}

.loading-user {
  color: #15a548;
  font-weight: bolder;
  font-size: 1.1rem;
  animation: loadingUser 1s linear infinite;
}

.cards__info {
  display: flex;
  grid-gap: 2rem;
}
.cards__info .card span {
  font-size: 44px;
}
.cards__info .card.naoEnviado{
  background-color: #000FFF33;
  color: #0000FFFF;
  border: 1px solid #000FFF33;
}
.cards__info .card.reprovado{
  background-color: #FFE6E0;
  color: #FF371A;
  border: 1px solid #FF371A33;
}

@keyframes loadingUser {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  font-size: 10px;
  line-height: 25px;
  font-weight: 800;
  margin-right: 10px;
}
</style>
